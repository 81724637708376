import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { urlFor, client } from "../../../client";
import PortableText from "react-portable-text";
import Sectors from "../../elements/slider/sectors";
import Slider from "../../elements/slider/slider-inner";

// Layout
import Header from "../../layout/header/header3";
import Footer from "../../layout/footer/footer2";

// Elements
import ScrollToTop from "../../elements/ScrollToTop";

const Corporate = () => {
  const [corporate, setCorporate] = useState([]);

  useEffect(() => {
    const query = '*[_type == "corporate"]';

    client.fetch(query).then((data) => setCorporate(data));
 
  }, []);

  return (
    <>
      <Header />
      <ScrollToTop />
      <Slider />

      <div className="page-content bg-white">
        

        <div className="section-area section-sp4 bg-white">
          <div className="container">
            {corporate.slice(0, 1).map((item) => (
              <div className="row ">
                <div className="col-lg-6 wow fadeInLeft" data-wow-delay="0.8s">
                  <h3 className="  text-dark m-b0">
                    <strong>{item.footfall}</strong>
                  </h3>
                  <br />
                  <h3 className=" text-dark m-b0">{item.sectionTitle}</h3>
                  <hr />
                  <p>
                    <small>
                      <PortableText
                        className="m-t30"
                        // Pass in block content straight from Sanity.io
                        content={item.sectionDesc}
                        projectId="z91hod28"
                        dataset="production"
                        // Optionally override marks, decorators, blocks, etc. in a flat
                        // structure without doing any gymnastics
                        
                      />
                    </small>
                  </p>
                </div>
                <div
                  className="col-lg-6 wow fadeInUp m-md-b30"
                  data-wow-delay="0.4s"
                >
                  <div className=" text-center m-b30 p-20">
                    <img src={urlFor(item.sectionImage)} alt="" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="content-block" id="content-area">
          {corporate.map((item) => (
            <div className="section-area section-sp4 ">
              <div className="container ">
                {/* <div className="head-in  m-b50">
                  <h4 className="text-left">{item.Section2title}</h4>
                </div>
                <div className="p-10 ">
                  <Sectors />
                </div> */}
                <div className="feature-container m-t30">
                  <h4>CORPORATE PROFILE</h4> <hr />
                  <p>
                    <PortableText
                      className="m-t30"
                      // Pass in block content straight from Sanity.io
                      content={item.profileText}
                      projectId="z91hod28"
                      dataset="production"
                      // Optionally override marks, decorators, blocks, etc. in a flat
                      // structure without doing any gymnastics
                      
                    />
                  </p>
                </div>
              </div>
            </div>
          ))}

          <div className="section-area section-sp5 bg-primary">
            <div className="container">
              <div className=" row text-center text-white style3">
                <div className="col-md-6 phone-center ">
                  <h4 className="title-head p-t10 ">
                    Connect with Pacific Group
                  </h4>
                </div>
                <div className="col-md-6 phone-center align-items-center p-t10 ">
                  <Link
                    to={"/contact"}
                    className="btn outline button-md  white"
                  >
                    Get Started Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Corporate;
