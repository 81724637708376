import React, { useEffect, useState } from "react";
import PortableText from "react-portable-text";
import { urlFor, client } from "../../../client"; // Ensure client is configured properly

const RewardTerms = () => {
  const [terms, setTerms] = useState(null);

  useEffect(() => {
    const query = '*[_type == "rewardTerms"]'; // Sanity GROQ query to fetch terms
    client
      .fetch(query)
      .then((data) => {
        if (data && data.length > 0) {
          setTerms(data[0]); // Assume you want the first document
        }
      })
      .catch((err) => console.error("Error fetching terms:", err));
  }, []);

  if (!terms) {
    return <div>Loading...</div>; // Display a loader while data is being fetched
  }

  return (
    <div>
         <div className="page-content bg-white">
            <div className="section-area section-sp4 bg-white termPage">
                <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                 
                                <h4 className="text-center mb-5">{terms.title}</h4>
                                <div>
                                    {/* Render the PortableText for the description */}
                                    <PortableText
                                    content={terms.description}
                                    serializers={{
                                        h1: (props) => <h1 className="text-2xl font-bold" {...props} />,
                                        h2: (props) => <h2 className="text-xl font-semibold" {...props} />,
                                        li: ({ children }) => <li className="ml-4 list-disc">{children}</li>,
                                        link: ({ href, children }) => (
                                        <a href={href} className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">
                                            {children}
                                        </a>
                                        ),
                                    }}
                                    />
                                </div>
                            </div>
                        </div>
                </div>
            </div>
       </div>
    </div>
  );
};

export default RewardTerms;
