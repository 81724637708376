import React from "react";
import { Link } from "react-router-dom";
import Sectors from "../../elements/slider/sectors";
import Slider from "../../elements/slider/slider-inner";

// Layout
import Header from "../../layout/header/header3";
import Footer from "../../layout/footer/footer2";

// Elements
import ScrollToTop from "../../elements/ScrollToTop";

const About = () => {
  return (
    <>
      <Header />
      <ScrollToTop />
      <ScrollToTop/>
      <Slider />

      <div className="page-content bg-white">
           <div className="section-area section-sp4 ">
              <div className="container">
                <div className="feature-container">
                  <h4>ABOUT US</h4> <hr />
                  <p>Pacific India is a privately owned and operated group of companies that has been continuously leaving a mark for 30 years in the commercial sectors and service industries of Real Estate, Education, Shopping Centres and Hospitality. The group has been operating with the corporate philosophy of trust, quality and commitment and has been able to build a great reputation and goodwill amongst its clients.</p>
                  <p>Pacific India has carried out more than 50 multi-crore projects in various sectors and industries since its inception. The group has been successful in its projects because of the brilliant minds associated with the Pacific companies. The group encourages the prevalence of a healthy work environment in its offices, where employees are encouraged to offer their best services to the company and the clients.</p>
                  <p>Pacific Group has established its name in the real estate market by developing luxurious residential properties in Uttarakhand and Uttar Pradesh. The projects have received an immense amount of positive response and the group plans on developing and offering more residential projects in India, with the goal of redefining ‘luxury living’.</p>
                  <p>The Group has also enjoyed tremendous success in the education sector with its renowned educational institutions – Delhi Public School Indirapuram, Delhi Public School RajNagar Extension and Pacific World School. Pacific India plans on establishing more such renowned institutions in the sector, with the aim of securing the future of this nation through the minds of our children and youngsters.</p>
                  <p>Pacific India officiated its name among the general public with the launch of its namesake shopping centres in the form of shopping malls in Delhi NCR and Dehradun. The shopping centres added credibility to the group, aiding in the success of its real estate projects as well. The group plans on launching three new shopping centers in Delhi NCR and Haridwar by the end of 2025.</p>
                  <p>Pacific Group understands the value of integrity, dedication and success. The group has been able to set a benchmark for quality offerings through its exceptional projects and continues to exceed its own benchmarks every single day.</p>                 
                </div>
              </div>
           </div>
          
           <div className="section-area section-sp5 bg-fix bg-primary">
            <div className="container">
              <div className=" row text-center text-white style3">
                <div className="col-md-6 phone-center ">
                  <h4 className="title-head p-t10 ">
                    Let's Talk about your Ideas.
                  </h4>
                </div>
                <div className="col-md-6 phone-center align-items-center p-t10 ">
                  <Link
                    to={"/contact"}
                    className="btn outline button-md  white"
                  >
                    Get Started Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
       
      </div>

      <Footer />
    </>
  );
};

export default About;
